import React from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const NotFoundSection = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: 'url(/images/adventages-bg.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        border: '1px solid black',
        color: 'white',
        p: 2,
        textAlign: 'center',
      }}
    >
      <Typography variant="h3">
        Не нашли то, что искали?
      </Typography>
      <Typography variant="h5">
        Оставьте заявку и мы подберем технику под ваши условия
      </Typography>
      <Divider sx={{ width: '100%', my: 2, borderColor: 'white' }} />
      <Button
        component={Link}
        to="/contacts"
        size="large"
        variant="contained"
        color="warning"
        endIcon={<ArrowForwardIcon />}
      >
        Оставить заявку
      </Button>
    </Box>
  );
};

export default NotFoundSection;
