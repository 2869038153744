import React, { useState, useEffect } from 'react';
import { Stack, Box, TextField, Typography, Grid, MenuItem, Button, Alert } from '@mui/material';
import axios from 'axios';

import apiEndpoints from '../../apiEndpoints';

const MainDataFields = ({ id, setId, title, setTitle, description, setDescription, priceType, setPriceType, price, setPrice, categoryId, setCategoryId }) => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);

  const fetchCategories = () => {
    axios.get(apiEndpoints.GET_CATEGORIES)
      .then((response) => {
        setCategories(response.data);
        setError(null);
        if (response.data.length > 0) {
          setCategoryId(response.data[0].id); // установить значение по умолчанию
        }
      })
      .catch((error) => {
        console.error('Error fetching categories: ', error);
        setError('Ошибка при получении категорий');
      });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <Box mt={2} sx={{ '& .MuiTextField-root': { mt: 1 } }}>
      <Typography variant="h6">Основные данные</Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <TextField
        label="ID товара"
        variant="outlined"
        value={id}
        onChange={(e) => setId(e.target.value)}
        fullWidth
      />
      <TextField
        label="Заголовок товара"
        variant="outlined"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
      />
      <TextField
        label="Описание товара"
        variant="outlined"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        fullWidth
      />
      <Stack direction="row" spacing={1} alignItems="baseline">
        <TextField
          select
          label="Категория товара"
          value={categories.some(category => category.id === categoryId) ? categoryId : ""}
          onChange={(e) => setCategoryId(e.target.value)}
          fullWidth
          margin="normal"
        >
          {categories.map((category) => (
            <MenuItem key={category.id} value={category.id}>
              {category.name}
            </MenuItem>
          ))}
        </TextField>
        <Button variant="contained" color="primary" onClick={fetchCategories}>
          Обновить
        </Button>
      </Stack>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <TextField
              select
              label="Тип цены"
              value={priceType}
              onChange={(e) => setPriceType(e.target.value)}
              fullWidth
            >
              <MenuItem value="request">Цена по запросу</MenuItem>
              <MenuItem value="fixed">Указать цену</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            {priceType === 'fixed' && (
              <TextField
                label="Цена в рублях"
                variant="outlined"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                fullWidth
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default MainDataFields;
