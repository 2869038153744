import React, { useState } from 'react';
import { Box, IconButton, Avatar, Tooltip, Menu, MenuItem, Typography, ListItemIcon } from '@mui/material';
import { Link } from 'react-router-dom';
import { AccountCircle, ExitToApp, Dashboard, Settings } from '@mui/icons-material';

const settings = [
    //{ name: 'Profile', icon: <AccountCircle /> },
    //{ name: 'Account', icon: <Settings /> },
    //{ name: 'Logout', icon: <ExitToApp /> }
];

const UserMenu = () => {
    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <MenuItem component={Link} to="/admin" onClick={handleCloseUserMenu}>
                    <ListItemIcon>
                        <Dashboard />
                    </ListItemIcon>
                    <Typography textAlign="center">Dashboard</Typography>
                </MenuItem>			
                {settings.map((setting) => (
                    <MenuItem key={setting.name} onClick={handleCloseUserMenu}>
                        <ListItemIcon>
                            {setting.icon}
                        </ListItemIcon>
                        <Typography textAlign="center">{setting.name}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default UserMenu;
