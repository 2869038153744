import React, { useState } from 'react';
import { Box } from '@mui/material';
import ImageWithBlurBackground from './ImageWithBlurBackground';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const GoodImages = ({ images = [] }) => {
  const [mainImageIndex, setMainImageIndex] = useState(0);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  const mainImage = images.length > 0 ? images[mainImageIndex] : null;
  const displayText = images.length > 0 ? `${mainImageIndex + 1} из ${images.length}` : '';

  return (
    <Box>
      {/* Основное изображение */}
      <Box sx={{ marginBottom: 2 }}>
        <ImageWithBlurBackground 
          imageUrl={mainImage} 
          text={displayText}
        />
      </Box>

      {/* Миниатюры */}
      {images.length > 0 && (
        <Carousel
          responsive={responsive}
          showDots={true}
          infinite={false}
          keyBoardControl={true}
          containerClass="carousel-container"
          itemClass="carousel-item"
        >
          {images.map((image, index) => (
            <Box
              key={index}
              sx={{
                cursor: 'pointer',
                border: mainImageIndex === index ? '2px solid #000' : 'none',
                borderRadius: '4px',
              }}
              onClick={() => setMainImageIndex(index)}
            >
              <ImageWithBlurBackground imageUrl={image} />
            </Box>
          ))}
        </Carousel>
      )}
    </Box>
  );
};

export default GoodImages;
