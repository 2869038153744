import React, { useState } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import ImageUploader from './ImageUploader';
import FeatureFields from './FeatureFields';
import AddedGoodsList from './AddedGoodsList';
import MainDataFields from './MainDataFields';

import apiEndpoints from '../../apiEndpoints';

const AdminPanel = ({ onLogout }) => {
  const [goods, setGoods] = useState([]);
  const [images, setImages] = useState([]);
  const [features, setFeatures] = useState([{ id: uuidv4(), name: '', value: '' }]);
  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [priceType, setPriceType] = useState('request'); // 'request' or 'fixed'
  const [price, setPrice] = useState('');
  const [categoryId, setCategoryId] = useState(''); // new state for category id
  const [error, setError] = useState(null);

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
  };

  const handleRemoveImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleAddFeature = () => {
    setFeatures((prevFeatures) => [...prevFeatures, { id: uuidv4(), name: '', value: '' }]);
  };

  const handleFeatureChange = (index, field, value) => {
    setFeatures((prevFeatures) => {
      const newFeatures = [...prevFeatures];
      newFeatures[index][field] = value;
      return newFeatures;
    });
  };

  const handleRemoveFeature = (index) => {
    setFeatures((prevFeatures) => prevFeatures.filter((_, i) => i !== index));
  };

  const handleAddGood = () => {
    const newGood = {
      id: id || `good-${uuidv4()}`,
      title,
      description,
      price: priceType === 'fixed' ? price : null,
      price_type: priceType,
      features: JSON.stringify(features.filter((f) => f.name && f.value)),
      category_id: categoryId, // include category id in the request
    };

    const formData = new FormData();
    Object.keys(newGood).forEach((key) => {
      formData.append(key, newGood[key]);
    });

    images.forEach((image, index) => {
      formData.append('images', image);
    });

    const token = localStorage.getItem('jwtToken');
    axios
      .post(apiEndpoints.ADD_GOOD, formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then((response) => {
        setGoods((prevGoods) => [...prevGoods, response.data])
        resetForm();
        setError(null); // Сбрасываем ошибку при успешном добавлении
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          onLogout();
        }
        console.error('Error adding good: ', error);
        setError(error.message || 'Неизвестная ошибка (см. консоль)');
        resetForm();
      });
  };

  const resetForm = () => {
    setImages([]);
    setFeatures([{ id: uuidv4(), name: '', value: '' }]);
    setId('');
    setTitle('');
    setDescription('');
    setPrice('');
    setPriceType('request');
    setCategoryId(''); // reset category id
  };

  return (
    <Paper elevation={3} sx={{ p: 4, my: 4 }}>
      <Typography variant="h5" gutterBottom>Добавление товара</Typography>

      <ImageUploader images={images} handleImageUpload={handleImageUpload} handleRemoveImage={handleRemoveImage} />

      <MainDataFields
        id={id}
        setId={setId}
        title={title}
        setTitle={setTitle}
        description={description}
        setDescription={setDescription}
        priceType={priceType}
        setPriceType={setPriceType}
        price={price}
        setPrice={setPrice}
        categoryId={categoryId}
        setCategoryId={setCategoryId} // pass setCategoryId to MainDataFields
      />

      <FeatureFields
        features={features}
        handleFeatureChange={handleFeatureChange}
        handleAddFeature={handleAddFeature}
        handleRemoveFeature={handleRemoveFeature}
      />

      <Box mt={2}>
        <Button onClick={handleAddGood} variant="contained" color="primary">
          Добавить товар
        </Button>
      </Box>

      <AddedGoodsList goods={goods} error={error} />
    </Paper>
  );
};

export default AdminPanel;
