import React, { useState } from 'react';
import { Box, Container, Grid, Typography, TextField, Button, Paper, Snackbar, Alert } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PersonIcon from '@mui/icons-material/Person';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';
import apiEndpoints from '../apiEndpoints';
import Link from '@mui/material/Link';

const ContactForm = ({ good = 'Главная', good_url = 'https://arkuda64.ru' }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    comment: '',
  });

  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const parseName = (name) => {
    const nameParts = name.trim().split(' ');
    if (nameParts.length === 2) {
      return { first_name: nameParts[0], last_name: nameParts[1] };
    } else {
      return { first_name: 'Ошибка', last_name: 'Ошибка' };
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    //Yandex Metrika
    if (window.ym) {
      window.ym(97311527, 'reachGoal', 'send-crm-form');
    }

    const { name, phone, comment } = formData;
    const { first_name, last_name } = parseName(name);

    const payload = {
      name,
      first_name,
      last_name,
      source_phone: phone,
      good,
      good_url,
    };

    axios.post(apiEndpoints.ADD_LEAD, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status === 201) {
          setSnackbar({ open: true, message: 'Заявка успешно отправлена!', severity: 'success' });
          setFormData({ name: '', phone: '', comment: '' });
        } else {
          setSnackbar({ open: true, message: 'Не удалось отправить заявку.', severity: 'error' });
        }
      })
      .catch((error) => {
        setSnackbar({ open: true, message: 'Не удалось отправить заявку.', severity: 'error' });
      });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box
      sx={{
        py: 6,
        backgroundImage: 'url(/images/adventages-bg.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        border: '1px solid #000',
      }}
    >
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} sx={{ color: 'white' }}>
            <Box sx={{ mb: 3 }}>
              <img src="/images/logo.svg" alt="АркудаСпецТех" style={{ width: '250px' }} />
            </Box>
            <Typography variant="h6">Телефон</Typography>
            <Typography variant="body1" gutterBottom>
              <PhoneIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              <Link href="tel:+78452399974" color="inherit" sx={{ textDecoration: 'none' }}>
                +7 (8452) 39-99-74
              </Link>
            </Typography>

            <Typography variant="h6">E-mail</Typography>
            <Typography variant="body1" gutterBottom>
              <EmailIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              <Link href="mailto:bir2012sar@yandex.ru" color="inherit" sx={{ textDecoration: 'none' }}>
                bir2012sar@yandex.ru
              </Link>
            </Typography>

            <Typography variant="h6">Часы работы</Typography>
            <Typography variant="body1" gutterBottom>
              <ScheduleIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              Пн-Пт: с 8:00 до 18:00
            </Typography>

            <Typography variant="h6">Руководитель отдела продаж</Typography>
            <Typography variant="body1" gutterBottom>
              <PersonIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              Насонов Артём
            </Typography>
            <Typography variant="body1">
              <PhoneIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              <Link href="tel:+79271437772" color="inherit" sx={{ textDecoration: 'none' }}>
                +7 (927) 143-77-72
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
              <Typography variant="h6" gutterBottom>
                Обратная связь
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Имя и фамилия"
                  name="name"
                  placeholder="Укажите, пожалуйста, ваши имя и фамилию"
                  variant="outlined"
                  margin="normal"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
                <TextField
                  fullWidth
                  label="Телефон"
                  name="phone"
                  placeholder="+7 (495) 000-00-00"
                  variant="outlined"
                  margin="normal"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                />
                <TextField
                  fullWidth
                  label="Комментарий"
                  name="comment"
                  multiline
                  rows={4}
                  variant="outlined"
                  margin="normal"
                  value={formData.comment}
                  onChange={handleInputChange}
                />
                <Button type="submit" variant="contained" color="warning" sx={{ mt: 2 }} startIcon={<SendIcon />}>
                  Отправить
                </Button>
              </form>
            </Paper>
          </Grid>
        </Grid>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default ContactForm;
