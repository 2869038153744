import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Divider, Alert, CircularProgress, Paper, Grid, useMediaQuery, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';

import apiEndpoints from '../apiEndpoints';
import Layout from "../components/Layout";
import BackToCatalogButton from "../components/Good/BackToCatalogButton";
import OfferIconBreadcrumbs from "../components/Offer/OfferIconBreadcrumbs";
import ImageWithBlurBackground from '../components/Good/ImageWithBlurBackground';
import ContactForm from '../components/ContactForm';

import OfferTitle from '../components/Offer/OfferTitle';
import OfferPrice from '../components/Offer/OfferPrice';
import OfferDescription from '../components/Offer/OfferDescription';
import GoodSpecialOffer from '../components/Good/GoodSpecialOffer';

import { useLocation } from 'react-router-dom';

const OfferPage = () => {
    const { id } = useParams();
    const [offer, setOffer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [dialogOpen, setDialogOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    let location = useLocation();

    const handleRequestCall = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        axios.get(apiEndpoints.GET_OFFER(id))
            .then(response => {
                setOffer(response.data);
                setLoading(false);
            })
            .catch(error => {
                setError('Ошибка при получении спецпредложения!');
                setLoading(false);
            });
    }, [id]);

    if (loading) {
        return (
            <Layout>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <BackToCatalogButton />
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    <OfferIconBreadcrumbs />
                </Box>
                <Divider sx={{ mt: 1, mb: 4 }} />
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '20vh' }}>
                    <CircularProgress />
                </Box>
            </Layout>
        );
    }

    if (error) {
        return (
            <Layout>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <BackToCatalogButton />
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    <OfferIconBreadcrumbs />
                </Box>
                <Divider sx={{ mt: 1, mb: 4 }} />
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '20vh' }}>
                    <Alert severity="error">{error}</Alert>
                </Box>
            </Layout>
        );
    }

    return (
        <Layout>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <BackToCatalogButton />
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <OfferIconBreadcrumbs name={offer.title} />
            </Box>

            <Divider sx={{ mt: 1 }} />

            <Paper elevation={3} sx={{ p: 4, my: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} order={{ xs: 1 }}>
                        <OfferTitle title={offer.title} subtitle={offer.subtitle} />
                    </Grid>
                    <Grid item xs={12} md={7} order={{ xs: 3, md: 2 }}>
                        <ImageWithBlurBackground imageUrl={offer.img} />
                    </Grid>
                    <Grid item xs={12} md={5} order={{ xs: 3, md: 3 }}>
                        <OfferPrice onRequest={handleRequestCall} />
                    </Grid>
                    <Grid item xs={12} order={{ xs: 4, md: 4 }}>
                        <OfferDescription description={offer.description} />
                    </Grid>
                    <Grid item xs={12} order={{ xs: 5, md: 5 }}>
                        <GoodSpecialOffer />
                    </Grid>
                </Grid>
            </Paper>

            <Dialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                fullWidth
                maxWidth="md"
                fullScreen={isMobile}
            >
                <DialogTitle sx={{ bgcolor: 'primary.main', color: 'primary.contrastText' }}>
                    Контактная форма
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{ position: 'absolute', right: 8, top: 8, color: 'primary.contrastText' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <ContactForm good={offer.title} good_url={`${apiEndpoints.SITE_URL}${location.pathname}`} onSubmit={handleCloseDialog} />
                </DialogContent>
            </Dialog>
        </Layout>
    )
}

export default OfferPage;