// my-truck-app/src/components/Good/GoodTitle.js

import React from 'react';
import { Typography, Divider, Alert } from '@mui/material';

const GoodTitle = ({ title }) => (
  <>
    {title ? (
      <Typography variant="h4" gutterBottom>
        {title}
      </Typography>
    ) : (
      <Alert severity="info">Нет доступного заголовка</Alert>
    )}
    <Divider sx={{ mt: 1 }}/>
  </>
);

export default GoodTitle;
