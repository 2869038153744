// my-truck-app/src/components/Contacts/BackToCatalogButton.js

import React from 'react';
import { Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';

const BackToHomeButton = () => {

    return (
        <Button
            component={RouterLink}
            to="/"
            startIcon={<HomeIcon />}
            variant="text"
            color="primary"
            size="small"
        >
            Назад на главную
        </Button>
    );
};

export default BackToHomeButton;
