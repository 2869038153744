import React from 'react';
import { Box, Divider } from '@mui/material';
import Layout from '../components/Layout';

import ContactForm from '../components/ContactForm';
import BackToHomeButton from '../components/Contacts/BackToHomeButton';
import ContactsIconBreadcrumbs from '../components/Contacts/ContactsIconBreadcrumbs';

import apiEndpoints from '../apiEndpoints';

import { useLocation } from 'react-router-dom';

const ContactsPage = () => {
  let location = useLocation();
  
  return (
    <Layout>
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
        <BackToHomeButton />
      </Box>
      <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
        <ContactsIconBreadcrumbs />
      </Box>

      <Divider sx={{ mt: 1, mb: 4 }} />

      <ContactForm good="Контакты" good_url={`${apiEndpoints.SITE_URL}${location.pathname}`} />
    </Layout>
  )
};

export default ContactsPage;
