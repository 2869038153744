// src/components/LogoutButton/LogoutButton.js

import React from 'react';
import { Button } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const LogoutButton = ({ onLogout }) => {
    const handleLogout = () => {
        onLogout();
    };

    return (
        <Button
            variant="contained"
            color="secondary"
            onClick={handleLogout}
            startIcon={<ExitToAppIcon />}
        >
            Logout
        </Button>
    );
};

export default LogoutButton;
