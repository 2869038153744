// my-truck-app/src/components/Offer/OfferDescription.js

import React from 'react';
import { Typography, Alert, Divider } from '@mui/material';

const OfferDescription = ({ description }) => {
  // Функция для форматирования описания
  const formatDescription = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Описание:
      </Typography>
      {description ? (
        <Typography variant="body1">
          {formatDescription(description)}
        </Typography>
      ) : (
        <Alert severity="info">Нет доступного описания</Alert>
      )}
      <Divider sx={{ mt: 1 }}/>
    </>
  );
};

export default OfferDescription;
