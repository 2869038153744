import React from 'react';
import { Box, Typography, Alert } from '@mui/material';

const AddedGoodsList = ({ goods, error }) => (
  <Box mt={4}>
    <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
      Добавленные товары
    </Typography>
    {error && (
      <Alert severity="error" sx={{ mb: 2 }}>
        Ошибка добавления товара: {error}
      </Alert>
    )}
    <Box component="pre" bgcolor="#f5f5f5" p={2} borderRadius={2} sx={{ overflow: 'auto' }}>
      {JSON.stringify(goods, null, 2)}
    </Box>
  </Box>
);

export default AddedGoodsList;
