import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Contacts, Store, Info } from '@mui/icons-material';

const FooterLinksSection = () => {
  const linkStyle = {
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    textDecoration: 'none',
    marginBottom: '8px'
  };

  const iconStyle = {
    marginRight: '8px'
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Ссылки на страницы
      </Typography>
      <Typography variant="body1">
        <RouterLink to="/catalog" style={linkStyle}>
          <Store style={iconStyle} />
          Каталог
        </RouterLink>
      </Typography>
      <Typography variant="body1">
        <RouterLink to="/about" style={linkStyle}>
          <Info style={iconStyle} />
          О компании
        </RouterLink>
      </Typography>
      <Typography variant="body1">
        <RouterLink to="/contacts" style={linkStyle}>
          <Contacts style={iconStyle} />
          Контакты
        </RouterLink>
      </Typography>
    </Box>
  );
};

export default FooterLinksSection;
