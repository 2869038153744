import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, IconButton, Stack, TextField, Typography } from '@mui/material';

const FeatureFields = ({ features, handleFeatureChange, handleAddFeature, handleRemoveFeature }) => (
    <Box mt={2}>
        <Typography variant="h6">Характеристики</Typography>
        {features.map((feature, index) => (
            <Stack key={feature.id} mt={1} direction="row" spacing={2} alignItems="center" mb={2}>
                <TextField
                    label="Название характеристики"
                    variant="outlined"
                    value={feature.name}
                    onChange={(e) => handleFeatureChange(index, 'name', e.target.value)}
                    fullWidth
                />
                <TextField
                    label="Значение характеристики"
                    variant="outlined"
                    value={feature.value}
                    onChange={(e) => handleFeatureChange(index, 'value', e.target.value)}
                    fullWidth
                />
                <IconButton onClick={() => handleRemoveFeature(index)}>
                    <DeleteIcon />
                </IconButton>
            </Stack>
        ))}
        <Button onClick={handleAddFeature} variant="contained" color="primary" startIcon={<AddIcon />}>
            Добавить характеристику
        </Button>
    </Box>
);

export default FeatureFields;