import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Divider, Grid, Paper, Dialog, DialogContent, DialogTitle, IconButton, useMediaQuery, CircularProgress, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Layout from '../components/Layout';
import GoodTitle from '../components/Good/GoodTitle';
import GoodFeatures from '../components/Good/GoodFeatures';
import GoodPrice from '../components/Good/GoodPrice';
import GoodImages from '../components/Good/GoodImages';
import GoodDescription from '../components/Good/GoodDescription';
import GoodSpecialOffer from '../components/Good/GoodSpecialOffer';
import axios from 'axios';

import GoodIconBreadcrumbs from '../components/Good/GoodIconBreadcrumbs';
import GoodErrorIconBreadcrumbs from '../components/Good/GoodErrorIconBreadcrumbs';
import BackToCatalogButton from '../components/Good/BackToCatalogButton';
import ContactForm from '../components/ContactForm';

import apiEndpoints from '../apiEndpoints';

import { useLocation } from 'react-router-dom';

const GoodPage = () => {
  const { id } = useParams();
  const [good, setGood] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let location = useLocation();

  useEffect(() => {
    axios.get(apiEndpoints.GET_GOOD(id))
      .then(response => {
        setGood(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError('Ошибка при получении товара!');
        setLoading(false);
      });
  }, [id]);

  const handleRequestCall = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  if (loading) {
    return (
      <Layout>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <BackToCatalogButton />
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <GoodErrorIconBreadcrumbs />
        </Box>
        <Divider sx={{ mt: 1, mb: 4 }} />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '20vh' }}>
          <CircularProgress />
        </Box>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <BackToCatalogButton />
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <GoodErrorIconBreadcrumbs />
        </Box>
        <Divider sx={{ mt: 1, mb: 4 }} />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '20vh'  }}>
          <Alert severity="error">{error}</Alert>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
        <BackToCatalogButton />
      </Box>
      <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
        <GoodIconBreadcrumbs name={good.title} category_id={good.category_id} />
      </Box>

      <Divider sx={{ mt: 1 }} />

      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} order={{ xs: 1 }}>
            <GoodTitle title={good.title} />
          </Grid>
          <Grid item xs={12} md={3} order={{ xs: 3, md: 2 }}>
            <GoodFeatures features={good.features} />
          </Grid>
          <Grid item xs={12} md={4} order={{ xs: 4, md: 3 }}>
            <GoodPrice price_type={good.price_type} price={good.price} onRequest={handleRequestCall} />
          </Grid>
          <Grid item xs={12} md={5} order={{ xs: 2, md: 4 }}>
            <GoodImages images={good.images} />
          </Grid>
          <Grid item xs={12} order={{ xs: 5, md: 5 }}>
            <GoodDescription description={good.description} />
          </Grid>
          <Grid item xs={12} order={{ xs: 6, md: 6 }}>
            <GoodSpecialOffer />
          </Grid>
        </Grid>
      </Paper>

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
        fullScreen={isMobile}
      >
        <DialogTitle sx={{ bgcolor: 'primary.main', color: 'primary.contrastText' }}>
          Контактная форма
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{ position: 'absolute', right: 8, top: 8, color: 'primary.contrastText' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <ContactForm good={good.title} good_url={`${apiEndpoints.SITE_URL}${location.pathname}`} onSubmit={handleCloseDialog} />
        </DialogContent>
      </Dialog>
    </Layout>
  );
};

export default GoodPage;
