import React from 'react';
import { Box } from '@mui/material';

const Banner = () => {
  return (
    <Box
      sx={{
        backgroundImage: 'url(/images/banner-image.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: { xs: '200px', md: '400px' }, // Adjust height based on screen size
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        textAlign: 'center',
      }}
    >
      <Box
        component="img"
        sx={{
          height: 183,
          width: 779,
          maxHeight: { xs: 81, sm: 136, md: 183 },
          maxWidth: { xs: 350, sm: 538, md: 779 },
        }}
        alt="Arkuda64 Logo."
        src="/images/logo.svg"
      />
    </Box>
  );
};

export default Banner;
