import React from 'react';
import { Typography, IconButton } from '@mui/material';
import { Facebook, Twitter, Instagram } from '@mui/icons-material';

const FooterSocialSection = () => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Мы в социальных сетях
      </Typography>
      {/*<IconButton href="https://facebook.com" color="inherit" aria-label="Facebook">
        <Facebook />
      </IconButton>
      <IconButton href="https://twitter.com" color="inherit" aria-label="Twitter">
        <Twitter />
      </IconButton>*/}
      <IconButton href="https://www.instagram.com/arkydaspectex?igsh=eTd2MHkwM2tkYnUx" color="inherit" aria-label="Instagram">
        <Instagram />
      </IconButton>
    </>
  );
};

export default FooterSocialSection;
