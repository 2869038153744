import React from 'react';
import { Box, Typography } from '@mui/material';

import apiEndpoints from '../../apiEndpoints';

const ImageWithBlurBackground = ({ imageUrl, text }) => {
  const resolvedImageUrl = imageUrl ? `${apiEndpoints.BASE_URL}${imageUrl}` : '/images/default.jpg';
  const backgroundUrl = `url(${resolvedImageUrl})`;

  return (
    <Box
      sx={{
        position: 'relative',
        paddingTop: '75%', // Соотношение сторон 4:3
        overflow: 'hidden',
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'flex-end', // Выравниваем текст по нижнему краю
      }}
    >
      {/* Фон с размытием */}
      <Box
        sx={{
          backgroundImage: backgroundUrl,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'blur(15px)',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          transform: 'scale(1.15)', // Масштабируем фоновое изображение
        }}
      />

      {/* Основное изображение */}
      <Box
        component="img"
        src={resolvedImageUrl}
        alt="Main Image"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'transparent',
        }}
      />

      {/* Отображение текста, если он передан */}
      {text && (
        <Typography
          variant="caption"
          sx={{
            position: 'absolute',
            bottom: '5px',
            left: '5px',
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            padding: '2px 8px',
            borderRadius: '4px',
          }}
        >
          {text}
        </Typography>
      )}
    </Box>
  );
};

export default ImageWithBlurBackground;
