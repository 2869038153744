import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { Link } from 'react-router-dom';

const GoodSpecialOffer = () => {
  return (
    <Box sx={{ display: 'flex', border: '1px solid #000' }}>
      <Box
        sx={{
          width: '30%',
          backgroundImage: 'url(/images/banner_big.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Box
        sx={{
          backgroundImage: 'url(/images/adventages-bg.png)',
          backgroundPosition: 'center',
          flexGrow: 1,
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography color='white' variant="h5">СПЕЦПРЕДЛОЖЕНИЕ</Typography>
        <Typography color='white' variant="body1">✔ Лизинг на <strong>7</strong> лет</Typography>
        <Typography color='white' variant="body1">✔ Без первоначального взноса</Typography>
        <Typography color='white' variant="body1">✔ 100% одобрение</Typography>
        <Button
          component={Link}
          to="/contacts"
          variant="contained"
          color='warning'
          sx={{
            marginTop: 2,
            alignSelf: 'flex-start'
          }}
          startIcon={<SendIcon />}
        >
          Оставить заявку
        </Button>
      </Box>
      <Box
        sx={{
          width: '10%',
          backgroundImage: 'url(/images/banner_small.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
    </Box>
  );
};

export default GoodSpecialOffer;
