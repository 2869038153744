import React from 'react';
import { Box, Container, Grid } from '@mui/material';
import FooterLogoSection from './FooterLogoSection';
import FooterLinksSection from './FooterLinksSection';
import FooterSocialSection from './FooterSocialSection';

const Footer = () => {
  return (
    <Box sx={{ bgcolor: 'grey.900', color: 'white', py: 6 }}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <FooterLogoSection />
          </Grid>
          <Grid item xs={12} md={4}>
            <FooterLinksSection />
          </Grid>
          <Grid item xs={12} md={4}>
            <FooterSocialSection />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
