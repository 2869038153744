// src/pages/AdminPage.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Typography, Divider, CircularProgress, Alert } from '@mui/material';
import Layout from '../components/Layout';
import CategoryEditor from '../components/CategoryEditor/CategoryEditor';
import AdminPanel from '../components/AdminPanel/AdminPanel';
import CardGrid from '../components/CardGrid/CardGrid';
import AddOffer from '../components/OfferEditor/AddOffer';
import TitlebarImageList from '../components/TitlebarImageList/TitlebarImageList';
import LoginForm from '../components/LoginForm/LoginForm';
import LogoutButton from '../components/LogoutButton/LogoutButton';
import apiEndpoints from '../apiEndpoints';

const AdminPage = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [tokenExpiry, setTokenExpiry] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('jwtToken');
        if (token) {
            axios.get(apiEndpoints.VERIFY_TOKEN, {
                headers: { Authorization: `Bearer ${token}` }
            })
                .then(response => {
                    setIsAuthenticated(true);
                    setTokenExpiry(response.data.expires_in);
                })
                .catch(error => {
                    setError('Не удалось проверить токен. Войдите снова.');
                    setIsAuthenticated(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, []);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleLogin = (newToken) => {
        setLoading(true);
        setError(null);
        axios.get(apiEndpoints.VERIFY_TOKEN, {
            headers: { Authorization: `Bearer ${newToken}` }
        })
            .then(response => {
                localStorage.setItem('jwtToken', newToken);
                setIsAuthenticated(true);
                setTokenExpiry(response.data.expires_in);
            })
            .catch(error => {
                setError('Не удалось проверить токен. Пожалуйста, войдите снова.');
                setIsAuthenticated(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleLogout = () => {
        localStorage.removeItem('jwtToken');
        setIsAuthenticated(false);
        setLoading(false);
        setError(null);
        setTokenExpiry(null);
    };

    if (loading) {
        return (
            <Layout>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '20vh' }}>
                    <CircularProgress />
                </Box>
            </Layout>
        );
    }

    if (!isAuthenticated) {
        return (
            <Layout>
                {error && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '10vh' }}>
                        <Alert severity="error">{error}</Alert>
                    </Box>
                )}
                <LoginForm onLogin={handleLogin} />
            </Layout>
        );
    }

    const expiryDate = new Date(tokenExpiry * 1000).toLocaleString();

    return (
        <Layout>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <LogoutButton onLogout={handleLogout} />
                <Typography variant='body2'>Token expires at: {expiryDate}</Typography>
            </Box>
            <Divider sx={{ mt: 1, mb: 4 }} />

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTab} onChange={handleTabChange} aria-label="admin tabs">
                    <Tab label="Категории" />
                    <Tab label="Товары" />
                    <Tab label="Предложения" />
                </Tabs>
            </Box>
            <Box sx={{ display: selectedTab === 0 ? 'block' : 'none' }}>
                <CategoryEditor onLogout={handleLogout} />
                <Divider sx={{ my: 4 }} />
            </Box>
            <Box sx={{ display: selectedTab === 1 ? 'block' : 'none' }}>
                <AdminPanel onLogout={handleLogout} />
                <Divider sx={{ my: 4 }} />
                <CardGrid admin onLogout={handleLogout} />
                <Divider sx={{ my: 4 }} />
            </Box>
            <Box sx={{ display: selectedTab === 2 ? 'block' : 'none' }}>
                <AddOffer onLogout={handleLogout} />
                <Divider sx={{ my: 4 }} />
                <TitlebarImageList admin onLogout={handleLogout} />
                <Divider sx={{ my: 4 }} />
            </Box>
        </Layout>
    );
};

export default AdminPage;
