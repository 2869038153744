// my-truck-app/src/pages/NotFoundPage.js

import React from 'react';
import { Typography } from '@mui/material';
import Layout from '../components/Layout';

const NotFoundPage = () => {
  return (
    <Layout>
      <Typography variant="h3" gutterBottom>
        404 - Страница не найдена
      </Typography>
      <Typography variant="body1">
        Извините, страница, которую вы ищете, не существует. Пожалуйста, проверьте URL или перейдите на главную страницу.
      </Typography>
    </Layout>
  );
};

export default NotFoundPage;
