// src/apiEndpoints.js

const API_BASE_URL = 'https://api.arkuda64.ru';

const apiEndpoints = {
    BASE_URL: API_BASE_URL,
    LOGIN: `${API_BASE_URL}/auth/login`,
    VERIFY_TOKEN: `${API_BASE_URL}/auth/token/verify`, // Новый эндпоинт
    ADD_GOOD: `${API_BASE_URL}/goods/add_good`,
    GET_GOOD: (goodId) => `${API_BASE_URL}/goods/get_good/${goodId}`,
    DELETE_GOOD: (goodId) => `${API_BASE_URL}/goods/delete_good/${goodId}`,
    GET_GOODS: `${API_BASE_URL}/goods/get_goods`,
    
    ADD_CATEGORY: `${API_BASE_URL}/categories/add_category`,
    GET_CATEGORY: (categoryId) => `${API_BASE_URL}/categories/get_category/${categoryId}`,
    DELETE_CATEGORY: (categoryId) => `${API_BASE_URL}/categories/delete_category/${categoryId}`,
    GET_CATEGORIES: `${API_BASE_URL}/categories/get_categories`,
    
    ADD_OFFER: `${API_BASE_URL}/offers/add_offer`,
    GET_OFFER: (offerId) => `${API_BASE_URL}/offers/get_offer/${offerId}`,
    DELETE_OFFER: (offerId) => `${API_BASE_URL}/offers/delete_offer/${offerId}`,
    GET_OFFERS: `${API_BASE_URL}/offers/get_offers`,

    ADD_LEAD: `${API_BASE_URL}/amocrm/add_lead`, // Новый эндпоинт
    SITE_URL: 'https://arkuda64.ru'
};

export default apiEndpoints;
