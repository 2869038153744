// my-truck-app/src/pages/MainPage.js

import React from 'react';
import { Divider } from '@mui/material';
import Layout from '../components/Layout';
import TitlebarImageList from '../components/TitlebarImageList/TitlebarImageList';
import CardGrid from '../components/CardGrid/CardGrid';
import AboutCompany from '../components/AboutCompany';
import ContactForm from '../components/ContactForm';
import WhyMe from '../components/WhyMe';
import NotFoundSection from '../components/NotFoundSection';

const MainPage = () => (
  <Layout>
    <TitlebarImageList />
    <Divider sx={{ my: 4 }} />
    <CardGrid />
    <NotFoundSection />
    <Divider sx={{ my: 4 }} />
    <WhyMe />
    <Divider sx={{ my: 4 }} />
    <AboutCompany />
    <Divider sx={{ my: 4 }} />
    <ContactForm />
  </Layout>
);

export default MainPage;
