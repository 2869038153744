import React from 'react';
import { Box, Container } from '@mui/material';
import ResponsiveAppBar from './ResponsiveAppBar/ResponsiveAppBar';
import Banner from './Banner';
import Footer from './Footer/Footer';

const Layout = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <ResponsiveAppBar />
        <Banner />
        <Container sx={{ my: 4 }}>
          {children}
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default Layout;
