import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Button, IconButton } from '@mui/material';

const SingleImageUploader = ({ image, handleImageUpload, handleRemoveImage }) => (
  <Box>
    <input
      accept="image/*"
      id="upload-image"
      type="file"
      onChange={handleImageUpload}
      style={{ display: 'none' }}
    />
    <label htmlFor="upload-image">
      <Button variant="contained" color="primary" component="span" startIcon={<UploadFileIcon />}>
        Загрузить изображение
      </Button>
    </label>
    {image && (
      <Box position="relative" sx={{ mt: 2 }}>
        <Box position="relative" display="inline-block">
          <img src={URL.createObjectURL(image)} alt="Uploaded" style={{ width: '100%', maxWidth: '200px', display: 'block' }} />
          <IconButton
            size="small"
            onClick={handleRemoveImage}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    )}
  </Box>
);

export default SingleImageUploader;
