import React, { useState } from 'react';
import { AppBar, Box, Toolbar, IconButton, Menu, Container, Button, Tooltip, MenuItem, ListItemIcon, ListItemText, Divider, Typography } from '@mui/material';
import { Menu as MenuIcon, Phone, AddShoppingCart as AddShoppingCartIcon, Info as InfoIcon, Contacts as ContactsIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import ArkudaLogo from './ArkudaLogo';
import UserMenu from './UserMenu';
import { Link as NewLink } from '@mui/material';

const pages = [
    {
        content: 'Каталог',
        icon: <AddShoppingCartIcon />,
        route: '/catalog'
    },
    {
        content: 'О компании',
        icon: <InfoIcon />,
        route: '/about'
    },
    {
        content: 'Контакты',
        icon: <ContactsIcon />,
        route: '/contacts'
    }
];

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <ArkudaLogo mode="md" />

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem
                                    key={page.content}
                                    component={Link}
                                    to={page.route}
                                    onClick={handleCloseNavMenu}
                                >
                                    <ListItemIcon>
                                        {page.icon}
                                    </ListItemIcon>
                                    <ListItemText>
                                        {page.content}
                                    </ListItemText>
                                </MenuItem>
                            ))}
                            <Divider />
                            <MenuItem>
                                <ListItemIcon>
                                    <Phone />
                                </ListItemIcon>
                                <ListItemText>
                                    <NewLink href="tel:+78452399974" color="inherit" sx={{ textDecoration: 'none' }}>
                                        +7 (8452) 39-99-74
                                    </NewLink>
                                </ListItemText>
                            </MenuItem>
                        </Menu>
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page.content}
                                onClick={handleCloseNavMenu}
                                component={Link}
                                to={page.route}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page.content}
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', mr: 2 }}>
                        <Phone />
                        <NewLink href="tel:+78452399974" color="inherit" sx={{ textDecoration: 'none' }}>
                            <Typography variant="h6" sx={{ marginLeft: 1 }}>
                                +7 (8452) 39-99-74
                            </Typography>
                        </NewLink>
                    </Box>

                    <ArkudaLogo mode="xs" />

                    <UserMenu />
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default ResponsiveAppBar;
