// my-truck-app/src/components/Offer/OfferTitle.js

import React from 'react';
import { Typography, Divider, Alert } from '@mui/material';

const OfferTitle = ({ title, subtitle }) => (
    <>
        {title ? (
            <>
                <Typography color="text.primary" variant="h4">
                    {title}
                </Typography>
                <Typography
                    variant="subtitle1"
                    gutterBottom
                    color="text.secondary"
                >
                    {subtitle}
                </Typography>
            </>
        ) : (
            <Alert severity="info">Нет доступного заголовка</Alert>
        )}
        <Divider sx={{ mt: 1 }} />
    </>
);

export default OfferTitle;
