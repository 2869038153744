import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import Card from './Card';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import IconPositionTabs from './IconPositionTabs';
import { Typography, Paper } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

import apiEndpoints from '../../apiEndpoints';

const CardGrid = ({ admin, onLogout }) => {
  const [goods, setGoods] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [categoryId, setCategoryId] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchGoods = (page, categoryId) => {
    setLoading(true);
    let url = `${apiEndpoints.GET_GOODS}?page=${page}&per_page=8`;
    if (categoryId) {
      url += `&category_id=${categoryId}`;
    }

    axios.get(url)
      .then(response => {
        setGoods(response.data.goods);
        setTotalPages(response.data.pages);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching goods:', error);
        setError("Ошибка загрузки товаров");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchGoods(page, categoryId);
  }, [page, categoryId]);

  const handleDelete = (goodId) => {
    const token = localStorage.getItem('jwtToken');
    axios.delete(apiEndpoints.DELETE_GOOD(goodId), {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        setGoods(goods.filter(good => good.id !== goodId));
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          if (typeof onLogout === 'function') {
            onLogout();
          }
        }
        console.error('Error deleting good:', error);
      });
  };

  const handleRefresh = () => {
    fetchGoods(page, categoryId);
  };

  const handleCategoryChange = (id) => {
    setCategoryId(id);
    setPage(1);
  };

  const handlePageChange = (value) => {
    setPage(value);
  }

  if (error) {
    return (
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h5" gutterBottom>
          Каталог товаров
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '20vh' }}>
          <Alert severity="error">{error}</Alert>
        </Box>
      </Paper>
    );
  }

  return (
    <Paper elevation={3} sx={{ p: 4, my: 4 }}>
      <Typography variant="h5" gutterBottom>
        Каталог товаров
      </Typography>
      <IconPositionTabs onCategoryChange={handleCategoryChange} onRefresh={handleRefresh} />
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '20vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container spacing={3}>
            {goods.map((good, index) => (
              <Grid item xs={12} md={6} lg={3} key={index}>
                <Card good={good} admin={admin} onDelete={handleDelete} />
              </Grid>
            ))}
          </Grid>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ mt: 3 }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={(event, value) => handlePageChange(value)}
              color="primary"
            />
          </Box>
        </>
      )}
    </Paper>
  );
};

export default CardGrid;
