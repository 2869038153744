// my-truck-app/src/components/GoodFeatures.js

import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Alert } from '@mui/material';

const GoodFeatures = ({ features }) => (
  <Box>
    <Typography variant="h6" gutterBottom>
      Характеристики:
    </Typography>  
    {features.length > 0 ? (
      <TableContainer component={Paper} sx={{ backgroundColor: '#f7f7f7' }}>
        <Table>
          <TableBody>
            {features.map((feature) => (
              <TableRow key={feature.id}>
                <TableCell sx={{ fontWeight: 'bold' }}>{feature.name}</TableCell>
                <TableCell>{feature.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <Alert severity="info">Нет доступных характеристик</Alert>
    )}
  </Box>
);

export default GoodFeatures;
