import React from 'react';
import { Typography, Grid, Paper, Box } from '@mui/material';

const imageUrls = [
  '/images/logo/logoza1.png',
  '/images/logo/logoza2.png',
  '/images/logo/logoza3.png',
  '/images/logo/logoza4.png',
];

const ImageCard = ({ imageUrl }) => (
  <Grid item xs={6} sm={4} md={3}>
    <Paper sx={{p:0.5}}>
      <Box
        sx={{
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: "100%",
          paddingTop: '100%',
        }}
      />
    </Paper>
  </Grid>
);

const WhyMe = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h4" gutterBottom>
        ПОЧЕМУ КЛИЕНТЫ ДОВЕРЯЮТ АРКУДАСПЕЦТЕХ?
      </Typography>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {imageUrls.map((url, index) => (
          <ImageCard key={index} imageUrl={url} />
        ))}
      </Grid>
    </Box>
  );
};

export default WhyMe;
