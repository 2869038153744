import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import apiEndpoints from '../../apiEndpoints';
import FeaturedImageListItem from './FeaturedImageListItem';
import OfferTabs from './OfferTabs';

function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop`,
    srcSet: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&dpr=2 2x`,
  };
}

export default function TitlebarImageList({ admin, onLogout }) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));

  let cols = 4; // default number of columns
  if (isXs) cols = 1;
  else if (isSm) cols = 2;
  else if (isMd) cols = 3;
  else if (isLg) cols = 4;
  else if (isXl) cols = 5;

  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchOffers = (page = 1, perPage = 10) => {
    setLoading(true);
    axios.get(apiEndpoints.GET_OFFERS, { params: { page, per_page: perPage } })
      .then(response => {
        const fetchedOffers = response.data.offers.map(offer => ({
          ...offer,
          featured: Math.random() < 0.5, // случайное определение featured
        }));
        setOffers(fetchedOffers);
        setTotalPages(response.data.pages);
        setLoading(false);
      })
      .catch(error => {
        setError('Ошибка загрузки предложений');
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchOffers(page);
  }, [page]);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const handleDelete = (offerId) => {
    const token = localStorage.getItem('jwtToken');
    axios.delete(apiEndpoints.DELETE_OFFER(offerId), {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        setOffers(offers.filter(offer => offer.id !== offerId));
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          if (typeof onLogout === 'function') {
            onLogout();
          }
        }
        console.error('Error deleting offer:', error);
      });
  }

  if (loading) {
    return (
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h5" gutterBottom>
          Текущие спецпредложения
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '20vh' }}>
          <CircularProgress />
        </Box>
      </Paper>
    );
  }

  if (error) {
    return (
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h5" gutterBottom>
          Текущие спецпредложения
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '20vh' }}>
          <Alert severity="error">{error}</Alert>
        </Box>
      </Paper>
    );
  }

  return (
    <Paper elevation={3} sx={{ p: 4, my: 4 }}>
      <Typography variant="h5" gutterBottom>
        Текущие спецпредложения
      </Typography>
      <OfferTabs onTabChange={handleTabChange} onRefresh={() => fetchOffers(page)} />
      <ImageList sx={{ width: '100%', height: 'auto' }} cols={cols} gap={8}>
        {offers.map((offer) => {
          const { src, srcSet } = srcset(`${apiEndpoints.BASE_URL}${offer.img}`, 248, 248, offer.rows, offer.cols);
          const ItemComponent = offer.featured ? FeaturedImageListItem : ImageListItem;

          return (
            <ItemComponent key={offer.id} cols={offer.cols || 1} rows={offer.rows || 1}>
              <img
                src={src}
                srcSet={srcSet}
                alt={offer.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={offer.title}
                subtitle={offer.subtitle}
                actionIcon={
                  <>
                    <IconButton
                      onClick={() => {
                        if (window.ym) {
                          window.ym(97311527,'reachGoal','see-offer');
                        }
                      }}
                      component={Link}
                      to={`/offer/${offer.id}`}
                      sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                      aria-label={`info about ${offer.title}`}
                    >
                      <InfoIcon />
                    </IconButton>
                    {admin && (
                      <IconButton
                        onClick={() => handleDelete(offer.id)}
                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                        aria-label={`Delete ${offer.title}`}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </>
                }
              />
            </ItemComponent>
          );
        })}
      </ImageList>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, value) => setPage(value)}
          color="primary"
        />
      </Box>
    </Paper>
  );
}
