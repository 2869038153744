import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import axios from 'axios';

import apiEndpoints from '../../apiEndpoints';

const IconPositionTabs = ({ onCategoryChange, onRefresh }) => {
    const [value, setValue] = useState(0);
    const [categories, setCategories] = useState([]);

    const fetchCategories = () => {
        axios.get(apiEndpoints.GET_CATEGORIES)
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.error('Error fetching categories:', error);
            });
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        onCategoryChange(newValue === 0 ? null : categories[newValue - 1].id);
    };

    const handleRefresh = () => {
		setValue(0);
        fetchCategories();
        onRefresh();
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', borderBottom: 1, borderColor: 'divider', mb: 1 }}>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="category choose"
                sx={{
                    flexGrow: 1,
                    [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                    },
                }}
            >
                <Tab label="ВСЁ" />
                {categories.map((category, index) => (
                    <Tab key={category.id} label={category.name} />
                ))}
            </Tabs>
            <IconButton onClick={handleRefresh} sx={{ ml: 2 }}>
                <RefreshIcon />
            </IconButton>
        </Box>
    );
};

export default IconPositionTabs;
