//my-truck-app/src/components/CategoryEditor/CategoryEditor.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Button, TextField, List, ListItem, ListItemText, IconButton, Snackbar, Alert, Paper, Grid, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import apiEndpoints from '../../apiEndpoints';

import RefreshIcon from '@mui/icons-material/Refresh';

const CategoriesViewer = ({ categories, onDelete, onUpdate }) => {
    return (
        <Box sx={{ p: 2, borderRadius: 2, bgcolor: 'background.paper', boxShadow: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h6" gutterBottom>
                    Список категорий
                </Typography>
                <IconButton edge="end" aria-label="update" onClick={onUpdate}>
                    <RefreshIcon />
                </IconButton>
            </Box>
            <List>
                {categories.map((category) => (
                    <ListItem key={category.id} divider>
                        <ListItemText primary={category.name} />
                        <IconButton edge="end" aria-label="delete" onClick={() => onDelete(category.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

const CategoryAdder = ({ onAdd }) => {
    const [name, setName] = useState('');
    const handleAdd = () => {
        onAdd(name);
        setName('');
    };

    return (
        <Box sx={{ p: 2, borderRadius: 2, bgcolor: 'background.paper', boxShadow: 3 }}>
            <Typography variant="h6" gutterBottom>
                Добавить категорию
            </Typography>
            <TextField
                label="Имя категории"
                value={name}
                onChange={(e) => setName(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleAdd}>
                Добавить категорию
            </Button>
        </Box>
    );
};

const CategoryEditor = ({ onLogout }) => {
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const fetchCategories = () => {
        axios.get(apiEndpoints.GET_CATEGORIES)
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                setError(error.message);
                setOpenSnackbar(true);
            });
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    const handleAddCategory = (name) => {
        const token = localStorage.getItem('jwtToken');
        axios.post(apiEndpoints.ADD_CATEGORY, { name }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => {
                setCategories([...categories, response.data]);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    onLogout();
                }
                setError(error.message);
                setOpenSnackbar(true);
            });
    };

    const handleDeleteCategory = (id) => {
        const token = localStorage.getItem('jwtToken');
        axios.delete(apiEndpoints.DELETE_CATEGORY(id), {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(() => {
                setCategories(categories.filter((category) => category.id !== id));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    onLogout();
                }                
                setError(error.message);
                setOpenSnackbar(true);
            });
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Paper elevation={3} sx={{ p: 4, my: 4 }}>
            <Typography variant="h5" gutterBottom>
                Редактирование категорий
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <CategoriesViewer
                        categories={categories}
                        onDelete={handleDeleteCategory}
                        onUpdate={fetchCategories}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CategoryAdder onAdd={handleAddCategory} />
                </Grid>
            </Grid>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default CategoryEditor;
