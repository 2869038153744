import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Button, Grid, IconButton } from '@mui/material';

// Компонент для загрузки изображений
const ImageUploader = ({ images, handleImageUpload, handleRemoveImage }) => (
    <Box>
        <input
            accept="image/*"
            id="upload-images"
            multiple
            type="file"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
        />
        <label htmlFor="upload-images">
            <Button variant="contained" color="primary" component="span" startIcon={<UploadFileIcon />}>
                Загрузить изображения
            </Button>
        </label>
        <Grid container spacing={2} sx={{ mt: 2 }}>
            {images.map((img, index) => (
                <Grid item key={index} xs={6} sm={4} md={3} lg={2} position="relative">
                    <Box position="relative">
                        <img src={URL.createObjectURL(img)} alt={`Uploaded ${index}`} style={{ width: '100%' }} />
                        <IconButton
                            size="small"
                            onClick={() => handleRemoveImage(index)}
                            sx={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            }}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Box>
                </Grid>
            ))}
        </Grid>
    </Box>
);

export default ImageUploader;