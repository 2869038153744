import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Call, PhoneInTalk, AccessTime, Email } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';

const FooterLogoSection = () => {
  return (
    <>
      <img src="/images/logo.svg" alt="АркудаСпецТех" style={{ width: '150px' }} />
      <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
        <Call sx={{ mr: 1 }} />
        <Link href="tel:+78452399974" color="inherit" sx={{ textDecoration: 'none' }}>
          <Typography variant="body1">
            +7 (8452) 39-99-74
          </Typography>
        </Link>
      </Box>
      <Button
        component={RouterLink}
        to="/contacts"
        variant="contained"
        color="warning"
        startIcon={<PhoneInTalk />}
        sx={{ mt: 2 }}
      >
        Заказать звонок
      </Button>
      <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
        <AccessTime sx={{ mr: 1 }} />
        <Typography variant="body1">
          Пн-Пт: с 8:00 до 18:00
        </Typography>
      </Box>
      <Button
        href="mailto:bir2012sar@yandex.ru"
        variant="contained"
        color="warning"
        startIcon={<Email />}
        sx={{ mt: 2 }}
      >
        bir2012sar@yandex.ru
      </Button>
    </>
  );
};

export default FooterLogoSection;
