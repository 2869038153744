import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import StoreIcon from '@mui/icons-material/Store';
import InventoryIcon from '@mui/icons-material/Inventory';
import CategoryIcon from '@mui/icons-material/Category';

export default function GoodErrorIconBreadcrumbs() {
    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    component={RouterLink}
                    to="/"
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                >
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    АркудаСпецТех
                </Link>
                <Link
                    component={RouterLink}
                    to="/catalog"
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                >
                    <StoreIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    Каталог
                </Link>
                <Typography
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="text.primary"
                >
                    <CategoryIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    Неизвестная категория
                </Typography>
                <Typography
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="text.primary"
                >
                    <InventoryIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    Неизвестный товар
                </Typography>
            </Breadcrumbs>
        </>
    );
}
