import React from 'react';
import { Box, Divider } from '@mui/material';
import Layout from '../components/Layout';
import CardGrid from '../components/CardGrid/CardGrid';

import BackToHomeButton from '../components/Contacts/BackToHomeButton';
import CatalogIconBreadcrumbs from '../components/Catalog/CatalogIconBreadcrumbs';

import NotFoundSection from '../components/NotFoundSection';

const CatalogPage = () => (
  <Layout>
    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
      <BackToHomeButton />
    </Box>
    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
      <CatalogIconBreadcrumbs />
    </Box>

    <Divider sx={{ my: 1 }} />

    <CardGrid />

    <NotFoundSection />
  </Layout>
);

export default CatalogPage;
