// my-truck-app/src/components/Good/GoodDescription.js

import React from 'react';
import { Typography, Alert, Divider } from '@mui/material';

const GoodDescription = ({ description }) => (
  <>
    <Typography variant="h6" gutterBottom>
      Описание:
    </Typography>
    {description ? (
      <Typography variant="body1">
        {description}
      </Typography>
    ) : (
      <Alert severity="info">Нет доступного описания</Alert>
    )}
    <Divider sx={{ mt: 1 }}/>
  </>
);

export default GoodDescription;
