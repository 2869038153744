// my-truck-app/src/components/Good/BackToCatalogButton.js

import React from 'react';
import { Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BackToCatalogButton = () => {

    return (
        <Button
            component={RouterLink}
            to="/catalog"
            startIcon={<ArrowBackIcon />}
            variant="text"
            color="primary"
            size="small"
        >
            Назад к каталогу
        </Button>
    );
};

export default BackToCatalogButton;
