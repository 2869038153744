import React from 'react';
import { Box, Button, SvgIcon } from '@mui/material';
import { Link } from 'react-router-dom';

function LogoIcon({ scale = 1, ...props }) {
    const logoWidth = Math.round(187 * scale);
    const logoHeight = Math.round(44 * scale);

    return (
        <SvgIcon
            {...props}
            viewBox={`0 0 ${logoWidth} ${logoHeight}`}
            sx={{ width: logoWidth, height: logoHeight, transform: 'scale(1)' }}
        >
            <image href="/images/logo.svg" width={logoWidth} height={logoHeight} />
        </SvgIcon>
    );
}

function ArkudaLogo({ mode }) {
    const isXs = mode === "xs";
    const displayProps = isXs
        ? { xs: 'flex', md: 'none' }
        : { xs: 'none', md: 'flex' };

    return (
        <Box sx={{ display: displayProps, mr: 1, flexGrow: isXs ? 1 : 0 }}>
            <Button
                variant="contained"
                color="primary"
                disableElevation
                component={Link} // Добавляем компонент Link
                to="/" // Указываем путь на главную страницу
                sx={{
                    color: 'white',
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                        backgroundColor: 'primary.main',
                        boxShadow: 'none',
                        transform: 'scale(1.1)'
                    }
                }}
            >
                <LogoIcon scale={0.8} />
            </Button>
        </Box>
    );
}

export default ArkudaLogo;
