import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import StarsIcon from '@mui/icons-material/Stars';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

export default function OfferIconBreadcrumbs({ name }) {
    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    component={RouterLink}
                    to="/"
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                >
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    АркудаСпецТех
                </Link>
                <Typography
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="text.primary"
                >
                    <StarsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    Спецпредложения
                </Typography>
                <Typography
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="text.primary"
                >
                    <StarOutlineIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    {name ? name : "Неизвестное спецпредложение"}
                </Typography>
            </Breadcrumbs>
        </>
    );
}
