import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import Layout from '../components/Layout';

import AboutIconBreadcrumbs from '../components/About/AboutIconBreadcrumbs';
import BackToHomeButton from '../components/Contacts/BackToHomeButton';
import AboutCompany from '../components/AboutCompany';
import GoodSpecialOffer from '../components/Good/GoodSpecialOffer';
import WhyMe from '../components/WhyMe';

const AboutPage = () => (
  <Layout>
    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
      <BackToHomeButton />
    </Box>
    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
      <AboutIconBreadcrumbs />
    </Box>
    <Divider sx={{ mt: 1, mb: 4 }} />
    <AboutCompany />
    <Divider sx={{my: 4}}/>
    <WhyMe />
    <Divider sx={{my: 4}}/>
    <GoodSpecialOffer />
  </Layout>
);

export default AboutPage;
