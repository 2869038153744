import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import StoreIcon from '@mui/icons-material/Store';
import InventoryIcon from '@mui/icons-material/Inventory';
import CategoryIcon from '@mui/icons-material/Category';
import Alert from '@mui/material/Alert';

import apiEndpoints from '../../apiEndpoints';

export default function GoodIconBreadcrumbs({ name, category_id }) {
  const [category, setCategory] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (category_id) {
      axios.get(apiEndpoints.GET_CATEGORY(category_id))
        .then(response => {
          const categoryData = response.data;
          if (categoryData.name) {
            setCategory(categoryData);
          } else {
            setCategory({ name: "Неизвестная категория" });
          }
        })
        .catch(error => {
          setError("Не удалось загрузить категорию");
          setCategory({ name: "Неизвестная категория" });
        });
    }
  }, [category_id]);

  return (
    <>
      {error && <Alert severity="error">{error}</Alert>}
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          component={RouterLink}
          to="/"
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          АркудаСпецТех
        </Link>
        <Link
          component={RouterLink}
          to="/catalog"
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
        >
          <StoreIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Каталог
        </Link>
        {category && (
          <Typography
            sx={{ display: 'flex', alignItems: 'center' }}
            color="text.primary"
          >
            <CategoryIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            {category.name}
          </Typography>
        )}
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
          <InventoryIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          {name ? name : "Товар"}
        </Typography>
      </Breadcrumbs>
    </>
  );
}
