import ImageListItem from '@mui/material/ImageListItem';
import { styled } from '@mui/material/styles';

const FeaturedImageListItem = styled(ImageListItem)(({ theme }) => ({
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  overflow: 'hidden',
  position: 'relative',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(145deg, rgba(0,0,0,0.3), rgba(0,0,0,0.1))',
    zIndex: 1,
  },
  '& img': {
    transition: 'transform 0.3s ease-in-out',
  },
  '&:hover img': {
    transform: 'scale(1.1)',
  },
  '& .MuiImageListItemBar-root': {
    background: 'rgba(0, 0, 0, 0.7)',
    zIndex: 2,
  },
}));

export default FeaturedImageListItem;
