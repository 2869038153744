import React from 'react';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';

const OfferTabs = ({ onTabChange, onRefresh }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onTabChange(newValue);
  };

  const handleRefresh = () => {
    setValue(0);
    onRefresh();
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', borderBottom: 1, borderColor: 'divider', mb: 1 }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="offer type choose"
        sx={{
          flexGrow: 1,
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
      >
        <Tab label="ВСЁ" />
        {/*<Tab label="Особые" />
        <Tab label="Классические" />*/}
      </Tabs>
      <IconButton onClick={handleRefresh} sx={{ ml: 2 }}>
        <RefreshIcon />
      </IconButton>
    </Box>
  );
};

export default OfferTabs;
