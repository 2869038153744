import React from 'react';
import { Link } from 'react-router-dom';
import { Card as MuiCard, Divider, CardMedia, CardContent, Typography, Button, Box, CardActions, Alert, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import apiEndpoints from '../../apiEndpoints';

// Функция для форматирования числа с разделителями тысяч
const formatPrice = (price) => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

// Подкомпонент для уменьшенного Alert
const SmallAlert = ({ message }) => (
  <Alert severity="info" sx={{ padding: '1px 8px', fontSize: '0.875rem', marginBottom: '8px' }}>
    {message}
  </Alert>
);

// Подкомпонент для заголовка
const CardTitle = ({ title }) => (
  title ? (
    <Typography gutterBottom variant="h5" component="div">
      {title}
    </Typography>
  ) : (
    <SmallAlert message="Без заголовка" />
  )
);

// Подкомпонент для описания
const CardDescription = ({ description }) => (
  description ? (
    <Typography variant="body2" color="text.secondary" sx={{ marginBottom: '8px' }}>
      {description.length > 50 ? `${description.substring(0, 50)}...` : description}
    </Typography>
  ) : (
    <SmallAlert message="Без описания" />
  )
);

// Подкомпонент для характеристик
const CardFeatures = ({ features }) => (
  features && features.length > 0 ? (
    <TableContainer component={Paper} sx={{ backgroundColor: '#f7f7f7', marginBottom: '8px' }}>
      <Table size="small">
        <TableBody>
          {features.slice(0, 3).map((feature) => (
            <TableRow key={feature.id}>
              <TableCell sx={{ fontWeight: 'bold' }}>{feature.name}</TableCell>
              <TableCell>{feature.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <SmallAlert message="Без характеристик" />
  )
);

// Подкомпонент для цены
const CardPrice = ({ price_type, price }) => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 1, border: '1px solid', borderColor: 'primary.main', borderRadius: 1 }}>
    <Typography variant="body1" color="text.secondary">
      {price_type === 'fixed' ? `${formatPrice(price)} ₽` : 'Цена по запросу'}
    </Typography>
  </Box>
);

// Подкомпонент для действий
const CardActionButtons = ({ id, admin, onDelete }) => (
  <>
    <Divider />
    <CardActions>
      <Button
        onClick={() => {
          if (window.ym) {
            window.ym(97311527,'reachGoal','see-good');
          }
        }}
        component={Link}
        to={`/good/${id}`}
        variant="contained"
        color="primary"
      >
        Подробнее
      </Button>
      {admin && (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}
          onClick={() => onDelete(id)}
        >
          Удалить
        </Button>
      )}
    </CardActions>
  </>
);

const Card = ({ good, admin, onDelete }) => {
  const {
    id,
    title,
    description,
    images,
    features,
    price_type,
    price
  } = good;

  const imageUrl = images.length > 0 ? `${apiEndpoints.BASE_URL}${images[0]}` : '/images/default.jpg';

  return (
    <MuiCard>
      <CardMedia component="img" height="140" image={imageUrl} alt={title} />
      <CardContent>
        <CardTitle title={title} />
        <CardDescription description={description} />
        <CardFeatures features={features} />
        <CardPrice price_type={price_type} price={price} />
      </CardContent>
      <CardActionButtons id={id} admin={admin} onDelete={onDelete} />
    </MuiCard>
  );
};

export default Card;
