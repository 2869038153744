import React, { useState } from 'react';
import {
    Paper,
    TextField,
    Button,
    Grid,
    Typography,
    MenuItem,
    Alert
} from '@mui/material';
import axios from 'axios';
import apiEndpoints from '../../apiEndpoints';
import SingleImageUploader from './SingleImageUploader';

const AddOffer = ({ onLogout }) => {
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [description, setDescription] = useState('');
    const [rows, setRows] = useState(1);
    const [cols, setCols] = useState(1);
    const [image, setImage] = useState(null);
    const [error, setError] = useState(null);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file);
        }
    };

    const handleImageDelete = () => {
        setImage(null);
    };

    const handleSubmit = (e) => {
        const token = localStorage.getItem('jwtToken');
        e.preventDefault();
        const formData = new FormData();
        formData.append('title', title);
        formData.append('subtitle', subtitle);
        formData.append('description', description);
        formData.append('rows', rows);
        formData.append('cols', cols);
        if (image) {
            formData.append('img', image);
        }

        axios.post(apiEndpoints.ADD_OFFER, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                console.log('Offer added:', response.data);
                // Очистка полей формы
                setTitle('');
                setSubtitle('');
                setDescription('');
                setRows(1);
                setCols(1);
                setImage(null);
                setError(null);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    onLogout();
                }
                console.error('Error adding offer:', error);
                setError(error.message || 'Неизвестная ошибка (см. консоль)');
            });
    };

    return (
        <Paper elevation={3} sx={{ p: 4, my: 4 }}>
            <Typography variant="h5" gutterBottom>
                Добавить новое предложение
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    {error && (
                        <Grid item xs={12}>
                            <Alert severity="error">
                                Ошибка добавления предложения: {error}
                            </Alert>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <SingleImageUploader
                            image={image}
                            handleImageUpload={handleImageUpload}
                            handleRemoveImage={handleImageDelete}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Заголовок"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Подзаголовок"
                            value={subtitle}
                            onChange={(e) => setSubtitle(e.target.value)}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Описание"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            fullWidth
                            multiline
                            rows={4}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Строки"
                            type="number"
                            value={rows}
                            onChange={(e) => setRows(parseInt(e.target.value, 10))}
                            fullWidth
                            inputProps={{ min: 1 }}
                            required
                            select
                        >
                            {[...Array(4).keys()].map((option) => (
                                <MenuItem key={option + 1} value={option + 1}>
                                    {option + 1}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Столбцы"
                            type="number"
                            value={cols}
                            onChange={(e) => setCols(parseInt(e.target.value, 10))}
                            fullWidth
                            inputProps={{ min: 1 }}
                            required
                            select
                        >
                            {[...Array(4).keys()].map((option) => (
                                <MenuItem key={option + 1} value={option + 1}>
                                    {option + 1}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary">
                            Добавить предложение
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    );
};

export default AddOffer;
