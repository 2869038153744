// src/components/LoginForm/LoginForm.js

import React, { useState } from 'react';
import { TextField, Button, Snackbar, Alert, Paper, Box, Typography, IconButton, InputAdornment, Divider } from '@mui/material';
import { Visibility, VisibilityOff, LockOpen } from '@mui/icons-material';
import axios from 'axios';
import apiEndpoints from '../../apiEndpoints';

const LoginForm = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleLogin = () => {
        axios.post(apiEndpoints.LOGIN, { name: username, password })
            .then((response) => {
                const token = response.data.access_token;
                localStorage.setItem('jwtToken', token);
                onLogin(token);
            })
            .catch((error) => {
                setError(error.response?.data?.error || 'Error logging in');
                setOpenSnackbar(true);
            });
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Paper elevation={3} sx={{ maxWidth: 400, margin: 'auto', padding: 3 }}>
            <Typography variant="h5" gutterBottom>
                Вход
            </Typography>
            <Divider sx={{ marginBottom: 2 }} />
            <TextField
                label="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                variant="outlined"
                margin="normal"
                fullWidth
            />
            <TextField
                label="Password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                margin="normal"
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                onMouseDown={(e) => e.preventDefault()}
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleLogin}
                startIcon={<LockOpen />}
                fullWidth
                sx={{ marginTop: 2 }}
            >
                Login
            </Button>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default LoginForm;
