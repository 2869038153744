import React from 'react';
import { Grid, Box, Typography } from '@mui/material';

const AboutCompany = () => (
    <Box>
        <Typography variant="h4" gutterBottom>
            ГРУЗОВЫЕ АВТОМОБИЛИ, СПЕЦТЕХНИКА, САМОСВАЛЫ, ПРИЦЕПЫ, РЕФРИЖЕРАТОРЫ С ПРОБЕГОМ
        </Typography>
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={6} position="relative">
                <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
                    Компания "АркудаСпецТех" предлагает надежную и проверенную спецтехнику для выполнения различных задач в строительстве, транспортировке и ремонте. Мы специализируемся на продаже грузовых автомобилей, самосвалов, прицепов и рефрижераторов с пробегом от ведущих мировых производителей.
                </Typography>
            </Grid>
            <Grid item xs={12} md={6} position="relative">
                <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
                    Наши модели проходят тщательную диагностику и предпродажную подготовку, что гарантирует их готовность к немедленной эксплуатации. Мы предлагаем выгодные условия лизинга и кредитования, чтобы вы могли быстро и удобно приобрести необходимую технику.
                </Typography>
            </Grid>
            <Grid item xs={12} md={6} position="relative">
                <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
                    Наши специалисты готовы предоставить полный спектр сопутствующих услуг, включая сервисное обслуживание, ремонт, и консультации по выбору техники. Сотрудничая с нами, вы получите надежную технику, которая поможет вам эффективно решать задачи любого масштаба.
                </Typography>
            </Grid>
            <Grid item xs={12} md={6} position="relative">
                <Typography variant="body1" sx={{ textAlign: 'justify' }}>
                    Доверяйте лидерам рынка – доверяйте "АркудаСпецТех"!
                </Typography>
            </Grid>
        </Grid>
    </Box>
)

export default AboutCompany;