// my-truck-app/src/components/Offer/OfferPrice.js

import React, { useRef, useEffect, useState } from 'react';
import { Box, Button, Typography, Paper, Alert } from '@mui/material';
import { LocalActivity as LocalActivityIcon, Phone, CallMade } from '@mui/icons-material';


const PriceInfo = () => {
  const [height, setHeight] = useState(0);
  const textBoxRef = useRef(null);

  useEffect(() => {
    if (textBoxRef.current) {
      setHeight(textBoxRef.current.offsetHeight);
    }
  }, []);

  return (
    <Box display="flex" alignItems="center" sx={{ justifyContent: 'space-between' }} marginBottom={1}>
      <Box sx={{ 
        //backgroundColor: '#00F', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        width: height, 
        height: height 
      }}>
        <LocalActivityIcon fontSize="large" style={{ fontSize: '64' }} />
      </Box>
      <Box ref={textBoxRef} marginLeft={2} display="flex" flexDirection="column" alignItems="flex-end">
        <Typography variant="h5" align="right">
          Свяжитесь с нами!
        </Typography>
        <Alert severity='success' sx={{ display: 'inline-flex', padding: '4px 8px', alignItems: 'center', mt: 0.5 }}>
          <Typography variant="body2">Мы на связи</Typography>
        </Alert>
      </Box>
    </Box>
  );
};

// Компонент для отображения кнопок
const ActionButtons = ({ onRequest }) => (
  <Box display="flex" flexDirection="column" alignItems="stretch">
    <Button
      variant="outlined"
      color="primary"
      startIcon={<Phone />}
      style={{ marginBottom: 8 }}
      onClick={onRequest}
    >
      +7 (8452) 39-99-74
    </Button>
    <Button
      variant="contained"
      color="primary"
      startIcon={<CallMade />}
      onClick={onRequest}
    >
      Заказать звонок
    </Button>
  </Box>
);

const OfferPrice = ({ onRequest }) => (
  <Paper elevation={3} style={{ padding: 16 }}>
    <PriceInfo />
    <ActionButtons onRequest={onRequest} />
  </Paper>
);

export default OfferPrice;
